<template>
	<div class="uploadFile">
		<Upload :accept='accept' :on-remove="removeFile" :on-success="handleSuccess" :data="{'md5':md5_key}" type="drag"
			name="file" ref="uploadSth" :max-size="51200" :headers="file_conf.headers" :on-exceeded-size="exceededSize"
			:before-upload="beforeUpload" :action="attachment_upload_url">
			<div class="uploadBtn">
				<span style="color: gray">上传需求附件（不超过50M）</span>
			</div>
		</Upload>
	</div>
</template>

<script>
	import SparkMD5 from "spark-md5";
	import {
		_file_conf,
	} from "../api/public.js"
	export default {
		data() {
			return {
				md5_key: '',
				file: '',
				file_conf: {},
				attachment_upload_url: '',
				loadingStatus: false,
				visible: false,
				imgName: '',
				imgObj: {},
				fileList: [],
				size: '',
				name: '',
			}
		},
		props: {
			info: {
				type: String,
			},
			accept: {
				type: String
			},
			format: {
				type: Array
			}
		},
		mounted() {
			this.file_conf = _file_conf
			this.file_conf.headers = {
				Authorization: localStorage.getItem('token')
			}
			this.attachment_upload_url = this.file_conf.uploadUrl
		},
		methods: {
			removeFile() {
				this.$refs.uploadSth.clearFiles()
				this.$emit('removeFile')
			},
			handleSuccess(response) {
				let res = {
					url: response.data,
					size: this.size,
					name: this.name
				}
				this.$emit('handleSuccess', res)
				this.fileList = this.$refs.uploadSth.fileList
			},
			beforeUpload(file) {
				this.file = file
				this.size = file.size
				this.name = file.name
				let _self = this;
				let fileReader = new FileReader();
				//用一个promise来进行文件上传前的操作，返回true就说明md5已经处理完毕
				let promise = new Promise((res, rej) => {
					let blobSlice = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice,
						chunkSize = 2097152,
						chunks = Math.ceil(file.size / chunkSize),
						currentChunk = 0,
						spark = new SparkMD5();
					fileReader.onload = function(e) {
						spark.appendBinary(e.target.result);
						currentChunk++;
						if (currentChunk < chunks) {
							loadNext();
						} else {
							_self.md5_key = spark.end();
							_self.md5_name = file.name
							res(true)
						}
					};

					function loadNext() {
						let start = currentChunk * chunkSize,
							end = start + chunkSize >= file.size ? file.size : start + chunkSize;
						fileReader.readAsBinaryString(blobSlice.call(file, start, end));
					}
					loadNext();
				})

				return promise;
			},
			exceededSize() {
				this.$msg.error({
					text: "上传文件大小超过50M"
				})
			},
			upload() {
				if (this.file == '') {
					this.$msg.error({
						text: "未选择任何文件"
					})
					return
				}
				if (this.fileList.length >= 1) {
					this.$msg.error({
						text: "超出可上传数量"
					})
					return
				}
				this.loadingStatus = true;
				this.$refs.uploadSth.post(this.file);
				this.loadingStatus = false
			},
		}

	}
</script>

<style scoped>
	.uploadBtn {
		background: center 60px no-repeat;
		background-image: url(../assets/image/customerOrder/upload.png);
		width: 200px;
		height: 175px;
		line-height: 310px;
	}
</style>
