<template>
	<div class="customerHome customerOrderSupplement">
		<div class="chuck">
			<div class="title">
				补充需求
			</div>
			<Form style="margin-top: 20px;" ref="supplementForm" :model="supplementForm" :label-width="80" label-colon
				:rules="supplementFormRules" inline>
				<FormItem style='width: 740px;' prop="requireText" label="描述">
					<Input maxlength="500" show-word-limit :rows="15" type="textarea" placeholder="描述"
						v-model="supplementForm.requireText" />
				</FormItem>
				<br>
				<FormItem prop="fileUrl" label="上传附件">
					<UploadFile info='上传附件' @removeFile='removeFile' @handleSuccess='handleSuccessFile'></UploadFile>
				</FormItem>
				<br>
				<FormItem style='width: 100%'>
					<ComfirmButton :customerStyle="customerStyle" ref="ComfirmButton" @comfirm='submit("supplementForm")'
						type='primary' info='提交'>
						<div slot="content">
							<div class="sumBox">
								<p class="text">确定提交？</p>
<!-- 								<br>
								<Button class="btn" type="primary" @click="submit('supplementForm')">确定</Button>
								<Button class="btn" style="margin-left: 20px;" type="default"
									@click="cancle()">取消</Button> -->
							</div>
						</div>
					</ComfirmButton>
				</FormItem>
			</Form>

		</div>
	</div>
</template>

<script>
	import {
		data_add
	} from "../../api/customerApi.js";
	import UploadFile from '../../components/UploadFile.vue'
	import ComfirmButton from '../../components/Comfirm.vue'
	export default {
		name: 'customerOrderSupplement',
		components: {
			UploadFile,
			ComfirmButton
		},
		data() {
			return {
				supplementForm: {
					requireText: '',
					fileUrl: '',
					fileSize: '',
				},
				supplementFormRules: {
					requireText: [{
						required: true,
						message: '必填项',
					}],
				},
				customerStyle: {
					'width': '140px',
					'height': '40px',
				},
			}
		},
		computed: {
			requireUid() {
				return this.$route.query.requireUid
			}
		},
		methods: {
			removeFile() {
				this.supplementForm.fileUrl = ''
			},
			handleSuccessFile(res) {
				this.supplementForm.fileUrl = res.url
				this.supplementForm.fileSize = res.size
			},
			submit(name) {
				this.$refs[name].validate(async (valid) => {
					if (valid) {
						let params = this.supplementForm
						params.requireUid = this.requireUid
						data_add(params).then(res => {
							this.$msg.success({
								text: "提交成功"
							})
							this.cancle()
							this.$refs[name].resetFields()
							// this.back()
							setTimeout(() => {
								this.back()
							}, 1000)
						}).catch(err => {
							this.$msg.error({
								text: err.data
							})
							this.cancle()
						})
					} else {
						this.$msg.error({
							text: "请输入必填项"
						})
						this.cancle()
					}
				})
			},
			cancle() {
				this.$refs.ComfirmButton.cancle()
			},
			back() {
				this.$router.push({
					path: '/customerorder/customerorder',
					query: {
						tab: 2
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/style/customerorder.scss";

	.sumBox {
		text-align: center;
		width: 100%;
		height: 120px;
		line-height: 160px;

		.text {
			width: 100%;
			text-align: center;
			font-size: 18px;
		}
		.btn {
			display: inline-block;
			width: 140px;
			height: 40px;
		}
	}
	.chuck{
		min-height: calc(100vh - 120px);
	}
</style>
