<!-- 
 二次确认的公共组件
 
 
 :info：按钮的内容
 slot content ：二次确认弹框的内容
 @comfirm ：点击确认后所执行的函数
 type：按钮类型
 disabled：按钮是否可以点击
 footerHide：是否隐藏弹框的footer
 size：按钮大小
 width：弹框的宽度
 showCancle：是否显示取消按钮
 fnParams：点击按钮的参数
 clickFn：点击按钮时执行的函数
 customerStyle:按钮样式
 
 -->
<template>
	<div class="ComfirmBox" :style="ComfirmBoxStyle">
		<Button :style="customerStyle" :disabled="disabled" :type=type :size='size' @click="toggle">{{info}}
			<Icon v-if='Icon' :type="Icon" />
		</Button>
		<Modal transfer class-name='vertical-center-modal' :width='width' v-model="showComfirm" :title="title"
			:footer-hide='footerHide' :mask-closable='maskClosable' :z-index='999'>
			<div class="btnContent">
				<slot name="content"></slot>
			</div>
			<div slot="footer" style="text-align: center;">
				<Button class="btn" type="info" @click="ok">确定</Button>
				<Button class="btn" v-if="showCancle" @click="cancle">我再想想</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	export default {
		name: 'Comfirm',
		props: {
			maskClosable:{
				type:Boolean,
				default:false
			},
			Icon: {
				type: String,
				default: ''
			},
			ComfirmBoxStyle: {
				type: Object,
				default: () => {}
			},
			customerStyle: {
				type: Object,
				default: () => {}
			},
			title: {
				type: String,
				default: ''
			},
			info: {
				type: String,
			},
			type: {
				type: String,
				default: 'primary'
			},
			disabled: {
				type: Boolean,
				default: false
			},
			footerHide: {
				type: Boolean,
				default: false
			},
			size: {
				type: String,
				default: 'default'
			},
			width: {
				type: Number,
				default: 600
			},
			showCancle: {
				type: Boolean,
				default: true
			},
			fnParams: {
				type: String,
			},
			clickFn: {
				type: Function,
				default: () => {}
			}

		},
		data() {
			return {
				showComfirm: false,
			}
		},
		methods: {
			toggle() {
				this.showComfirm = !this.showComfirm
				if (this.fnParams) {
					this.clickFn(this.fnParams)
				}
			},
			cancle() {
				this.showComfirm = false
			},
			ok() {
				this.showComfirm = false
				this.$emit('comfirm')
			},
		}
	}
</script>

<style scoped lang="scss">
	.ComfirmBox {
		display: inline-block;
	}

	.btn {
		width: 140px;
		height: 40px;
		display: inline-block;
	}

	/deep/ .ivu-modal-footer {
		border: none !important;
	}

	/* 	/deep/.ivu-modal {
		position: relative !important;
		top: 50% !important;
		transform: translateY(-70%) !important;
	} */
	/deep/.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}
</style>
